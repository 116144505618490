<template>

    <div>
      <p ><u><strong>Itemised Usage Report</strong></u> </p>
      <br>
      <div class="result-block-container">

        <div :class="['result-block', executed ? 'show' : '']">
        <b-form @submit="onSubmit" >

        <b-form-group
            id="fieldset-horizontal"
            label-cols-sm="2"
            label-cols-lg="3"
            content-cols-sm
            content-cols-lg="4"
            label="Carrier"
            label-for="input-horizontal"
            >
            <div>
                        <CarrierNameDropDownVue @carrier-changed="carrierChange"></CarrierNameDropDownVue>
                    </div>
            </b-form-group>
        <b-form-group
          id="fieldset-horizontal"
          label-cols-sm="2"
          label-cols-lg="3"
          content-cols-sm
          content-cols-lg="5"
          label="Account"
          label-for="input-horizontal"
        >
        <div >
            <CallAccountDropDownVue required @account-changed="accountChange"></CallAccountDropDownVue>
                </div>
        </b-form-group>
        <b-form-group
            id="fieldset-horizontal"
            label-cols-sm="1"
            label-cols-lg="3"
            content-cols-sm
            content-cols-lg="5"
            label="Destination starts with"
            label-for="input-horizontal"
            >
            <b-form-input id="form-fromdate-input"
                      type="text"   size="sm"
                      v-model="DilalledNumber"
                      placeholder=""
                       >
            </b-form-input>
            </b-form-group>
        <b-button type="submit" variant="primary">Find</b-button>
  </b-form>

         <br><br>
         <table v-show="showresult" >
            <!-- <tr>
                <td width="900"> <p style="color:blue;font-size:14px;" v-if="showresult"> Number of records found for {{ Account.Name }}  {{ Account.AccountNumber}}: {{ apiPricedCalls.length }}</p></td>
            </tr> -->
            <tr v-if="apiPricedCalls.length > 0">
                <td><button  type="button" class="btn btn-success btn-sm" v-on:click="download">Dowload Results</button></td>
            </tr>
        </table>
        <br><br>

         <!-- <p v-if="showresult"> Number of records found: {{ apiPricedCalls.length }}</p> -->
          <!-- <input type="search" v-model="filter"> -->
          <table class="table table-striped w-auto" v-show="showresult" id="apirecords">
            <thead>
              <tr>
                <th scope="col" @click="sort('GroupNumber')">Group Number</th>
                <th scope="col" @click="sort('GroupName')">Group Name</th>
                <th scope="col" @click="sort('AccountNumber')">Account Number</th>
                <th scope="col" @click="sort('AccountName')">Account Name</th>
                <th scope="col" @click="sort('CostCentreName')">Cost Centre Name</th>
                <th scope="col" @click="sort('BillingReference')">Billing Reference</th>
                <th scope="col" @click="sort('Label')">Label</th>
                <th scope="col" @click="sort('Origin')">Origin</th>
                <th scope="col" @click="sort('Type')">Type</th>
                <th scope="col" @click="sort('ChargeBand')">Charge Band</th>
                <th scope="col" @click="sort('Destination')">Destination</th>
                <th scope="col" @click="sort('Date')">Date</th>
                <th scope="col" @click="sort('Time')">Time</th>
                <th scope="col" @click="sort('Duration')">Duration (m)</th>
                <th scope="col" @click="sort('Data_mb')">Data (MB)</th>
                <th scope="col" @click="sort('Price')">Price (£)</th>
                <th scope="col" @click="sort('Cost')">Cost (£)</th>
                <th scope="col" @click="sort('Carrier')">Carrier</th>  
              </tr>
            </thead>
            <tbody>
              <tr v-for="(suspendedcall, index) in SortedapiPricedCalls" :key="index">
                 <td>{{ suspendedcall.GroupNumber }}</td>
                <td>{{ suspendedcall.GroupName }}</td>
               <td>{{ suspendedcall.AccountNumber }}</td>
                <td>{{ suspendedcall.AccountName }}</td>
                <td>{{ suspendedcall.CostCentreName }}</td>
                <td>{{ suspendedcall.BillingReference }}</td>
                <td>{{ suspendedcall.Label }}</td>
                <td>{{ suspendedcall.Origin }}</td>
                <td>{{ suspendedcall.Type }}</td>
                <td>{{ suspendedcall.ChargeBand }}</td>
                <td>{{ suspendedcall.Destination }}</td>
                <td>{{ suspendedcall.Date }}</td>
                <td>{{ suspendedcall.Time }}</td>
                <td>{{ suspendedcall.DurationMin }}</td>
                <td>{{ suspendedcall.Data_mb }}</td>
                <td>{{ suspendedcall.Price }}</td>
                <td>{{ suspendedcall.Cost }}</td>
                <td>{{ suspendedcall.Carrier }}</td>
                
              </tr>
            </tbody>
          </table>
          <div v-if="apiPricedCalls.length > 0"><p align="center">
            <b-pagination
              v-model="currentPage"
              align="center"
              :total-rows="filteredPricedCalls.length"
              :per-page="pageSize"
              aria-controls="apirecords"
              ></b-pagination>
            </p></div>
        </div>
      </div>

    </div>
  </template>

  <script>
  import Alert from '../components/Alert.vue';
  import Filter from '../components/Filter.vue';
  import CallAccountDropDownVue from "../components/CallAccountDropDown.vue";
  import * as XLSX from 'xlsx'
  import CarrierNameDropDownVue from "../components/CarrierNameDropDown.vue";

import {isAdmin, editButtonText} from '@/auth/AuthService'
export default {
  Name: "Api",
  data() {
    return {
      isAdmin: isAdmin(),
      editButtonText: editButtonText(),
        DilalledNumber: null,
        apiPricedCalls: [],
        executed: false,
        message: 'initial alert',
        currentSort:'Date',
        currentSortDir:'asc',
        pageSize:20,
        currentPage:1,
        filter:'',
        showresult: false,
        showdownload:false,
        Account: null
      };
    },

    computed:{
    filteredPricedCalls() {
      console.log('filtered suspendedcall');
        return this.apiPricedCalls
      },
    SortedapiPricedCalls:function() {
      console.log('sort suspendedcall');
      return this.filteredPricedCalls.slice().sort((a,b) => {
        let modifier = 1;
        if(this.currentSortDir === 'desc') modifier = -1;
        if(a[this.currentSort].toLowerCase() < b[this.currentSort].toLowerCase()) return -1 * modifier;
        if(a[this.currentSort].toLowerCase() > b[this.currentSort].toLowerCase()) return 1 * modifier;
        return 0;
      }).filter((row, index) => {
        let start = (this.currentPage-1)*this.pageSize;
        let end = this.currentPage*this.pageSize;
        if(index >= start && index < end) return true;
      });
    },
    },
    methods: {
    onChangeCallType(event) {
            this.SelectedCallType= event.target.value
            },

    onChangeDirection(event) {
        this.SelectedDirection= event.target.value
        },
    accountChange: function (e) {
        this.apiPricedCalls = null;
        this.showresult = false;
        console.log('£££££££££££ in change account £££££££')
        console.log(this.showresult)
        this.selectedAccount = e;
        },

    carrierChange: function (e) {
        this.selectedCarrier = e;
        },

    onSubmit(evt) {
        console.log('on submit');
        this.apiPricedCalls = null;
        this.showresult = false;
        // this.Account = null
        console.log(this.selectedAccount)
      if (!this.selectedCarrier) { // is null or zero
            if (!this.selectedAccount){ // is null or zero
            alert("Please select report criteria");
            evt.preventDefault();
                    return false;
            }
            // alert("Please select a carrier");
            // evt.preventDefault();
            //         return false;
            }
        this.callApi( this.selectedCarrier,this.selectedAccount,this.DilalledNumber);
        // this.showresult = true;
        evt.preventDefault();
        },

      download: function () {
        // customised headers
        const Heading = [
                            ['Group Number','Group Name','Account Number','Account Name','Cost Centre Name','Billing Reference','Label','Origin','Type','Charge Band','Destination','Date','Time','Duration (m)','Data (MB)','Price (£)','Cost (£)','Carrier']
                        ];

        // original object headers
        const objHeader =  ['GroupNumber','GroupName','AccountNumber','AccountName','CostCentreName','BillingReference','Label','Origin','Type','ChargeBand','Destination','Date','Time','DurationMin','Data_mb','Price','Cost','Carrier']
        // const objHeader =  ['AccountName','AccountNumber','Source','Dialled','Destination','CallType','CallDate','CallTime','Duration','Data_mb','Price','Carrier','Cost','Direction'];


        // build based on original object fields but skip the header row
        const data = XLSX.utils.json_to_sheet(this.apiPricedCalls, { origin: 'A2', header: objHeader, skipHeader: true })
        // add the customised header
        XLSX.utils.sheet_add_aoa(data, Heading, { origin: 'A1' });


        const wb = XLSX.utils.book_new()
        XLSX.utils.book_append_sheet(wb, data, 'data')
        var moment = require('moment');
        var datestr = moment(new Date()).format('DD-MM-YYYY');
        var filename =  'Itemised Usage Report - ' + datestr + '.xlsx';
        XLSX.writeFile(wb,filename)
        },

    // async getAccount(accountid) {
    //   console.log('getting the account data')
    //   try {
    //     const { data } = await this.$http.get(`${process.env.VUE_APP_API_BASE_URL}/api/v1/accounts/${accountid}`);

    //     this.Account = data;
    //     console.log('got the selected account data from api')
    //     console.log(this.Account.Name)
    //     // this.executed = true;
    //   } catch (e) {
    //     this.apiAccounts = `Error: the server responded with '${e.response}'`;
    //   }
    // },
    nextPage:function() {
        if((this.currentPage*this.pageSize) < this.filteredPricedCalls.length) this.currentPage++;
      },

    prevPage:function() {
        if(this.currentPage > 1) this.currentPage--;
      },

    sort:function(s) {
      //if s == current sort, reverse
      if(s === this.currentSort) {
        this.currentSortDir = this.currentSortDir==='asc'?'desc':'asc';
      }
      this.currentSort = s;
      },

      // *******************************************
      // List suspendedcalls
      // *******************************************
    async callApi( carrier, account, dialled) {

        console.log('getting the data')
      // build the filters for the url
        var filters =[];
        var accountfilter = {"name":"AccountId","op":"eq","val":account}
        var carrierfilter = { "name": "Carrier", "op": "eq", "val": carrier };
        var dialledfilter = { "name": "Dialled", "op": "eq", "val": dialled };

          if (typeof(carrier) != "undefined" && carrier != null && carrier != "") {
              filters.push(carrierfilter)
          }
          if (typeof(account) != "undefined" && account != null  && account != "") {
              filters.push(accountfilter)
          }
          if (typeof(dialled) != "undefined" && dialled != null && dialled != "") {
              filters.push(dialledfilter)
          }
          
        let queryfilters = JSON.stringify(filters);
        
        var url;
        document.body.style.cursor = 'wait';

        try {
          url = process.env.VUE_APP_API_BASE_URL + '/api/v1/reports/itemisedusage?q={"filters":' + queryfilters + '}';
          console.log(url)
          const { data } = await this.$http.get(url);
          console.log(data);
          this.apiPricedCalls = data;
          this.showresult = true
          this.executed = true;
        } catch (e) {
          this.apiPricedCalls = `Error: the server responded with '${e.response}'`;
        }
        document.body.style.cursor = 'auto';
      },

    },

    created() {
      console.log('created')
      this.executed = true;
    //   this.callApi();

    },
    components: {
      alert: Alert,
      CallAccountDropDownVue: CallAccountDropDownVue,
      CarrierNameDropDownVue: CarrierNameDropDownVue,
      listfilter:Filter
    },
  };
  </script>
