<template>

  <div>
    <div v-if="apiCostCentreDetails">
      <p><u><strong>Outbound Billing References ({{ apiCostCentreDetails.account.Name }} {{
            apiCostCentreDetails.account.AccountNumber }} : {{ apiCostCentreDetails.Name }})</strong></u></p>
    </div>
    <div v-else>
      <p v-if="apiCLIAccount"><u><strong>Outbound Billing References ({{ apiCLIAccount.Name }} {{
            apiCLIAccount.AccountNumber }})</strong></u></p>
      <p v-else><u><strong>Outbound Billing References </strong></u></p>
    </div>
    <br>
    <div class="result-block-container">
      <div :class="['result-block', executed ? 'show' : '']">
        <button v-if="isAdmin" type="button" class="btn btn-success btn-sm" v-b-modal.outbound-modal>Insert Outbound Billing
          Reference</button>&nbsp;&nbsp;&nbsp;
        <button v-if="isAdmin" type="button" class="btn btn-success btn-sm" v-b-modal.outbound-load-modal>Load
          outbounds</button>&nbsp;&nbsp;&nbsp;
        <a :href="'outbounds/deletedoutbounds?account_id=' + this.selected_account +'&accountname=' +  apiCLIAccount.Name + '&accountnumber='+ apiCLIAccount.AccountNumber"
          target="_blank">
          <button v-if="isAdmin" type="button" class="btn btn-danger btn-sm">Deleted</button>
        </a>
        <br><br>
        <input type="search" v-model="filter">
        <table class="table table-striped w-auto" id="apirecords">
          <thead>
            <tr>
              <th class="th-sm" scope="col" @click="sort('account.Name')">Account No</th>
              <th class="th-sm" scope="col" @click="sort('account.Name')">Account Name </th>
              <th class="th-sm" scope="col" @click="sort('account.Name')">Cost Centre No</th>
              <th class="th-sm" scope="col" @click="sort('account.Name')">Cost Centre Name</th>
              <th class="th-lg" scope="col" @click="sort('PhoneNumber')">Outbound</th>
              <th class="th-sm" scope="col" @click="sort('Label')">Label</th>
              <th class="th-sm" scope="col" @click="sort('Label')">Phone Type</th>
              <th scope="col"></th>
              <th></th>
              <th> </th>

            </tr>
          </thead>
          <tbody>
            <tr v-for="(outbound, index) in SortedapiCLIs" :key="index">
              <td>{{ outbound.account.AccountNumber }}</td>
              <td>{{ outbound.account.Name }}</td>
              <td v-if="outbound.costcentre">{{ outbound.costcentre.CostCentreNumber }}</td>
              <td v-else></td>
              <td v-if="outbound.costcentre">{{ outbound.costcentre.Name }}</td>
              <td v-else></td>
              <td>{{ outbound.PhoneNumber }}</td>
              <td>{{ outbound.Label }}</td>
              <td>{{ outbound.PhoneType }}</td>
              <td align="center"><a :href="'/recurringcharges?outbound_id=' + outbound.outbound_id" target="_blank">Recurring
                  Charges</a></td>
              <td align="center"><a :href="'/outboundhistory?outbound_id=' + outbound.outbound_id" target="_blank">History</a></td>
              <td>
                <div class="btn-group" role="group">
                  <button type="button" class="btn btn-warning btn-sm" v-b-modal.outbound-update-modal @click="editCLI(outbound)">
                    {{editButtonText}}
                  </button>
                  <p> </p>
                  <button v-if="isAdmin" type="button" class="btn btn-danger btn-sm" @click="onDeleteCLI(outbound)">
                    Delete
                  </button>
                </div>
              </td>
            </tr>
          </tbody>
        </table>
        <div v-show="showPaging">
          <p align="center">
            <b-pagination v-model="currentPage" align="center" :total-rows="filteredCLIs.length" :per-page="pageSize"
              aria-controls="apirecords"></b-pagination>
            <!-- <button class="btn btn-outline-primary mr-1" @click="prevPage">Previous</button>
            <button class="btn btn-outline-primary" @click="nextPage">Next </button> -->
          </p>
        </div>
      </div>
    </div>
    <!-- LOAD modal -->
    <b-modal ref="loadOutboundModal" id="outbound-load-modal" title="Load outbound billing references" hide-footer
      size="xs">
      <b-form @submit="onLoadSubmit" @reset="onLoadReset" class="w-70">
        <b-form-row>
          <b-col>
            <p>Select a comma seperated file (.txt).</p>
            <p><strong>Delete will fail if recurring charges reside on entry</strong></p>
            
          </b-col>
        </b-form-row>

        <b-form-row>
          <b-col>
            <b-form-file id="form-filename-input" type="file" size="sm" v-model="loadOutboundForm.FileName" required
              placeholder="Select outbound billing reference file">
            </b-form-file>
          </b-col>
        </b-form-row>

        <b-form-row>
          <b-col>
            <p></p>
          </b-col>
        </b-form-row>
        <div class="container">
          <div class="row">
            <div class="col text-center">
              <b-button-group>
                <b-button type="submit" variant="primary">Submit</b-button>
                <b-button type="reset" variant="danger">Reset</b-button>
                <b-button type="button" variant="info" v-on:click="download()">Download Template</b-button>
              </b-button-group>
            </div>
          </div>
        </div>
      </b-form>
    </b-modal>


    <!-- add modal -->
    <b-modal ref="addCLIModal" id="outbound-modal" title="Insert a new outbound billing reference" hide-footer size="m">
      <b-form @submit="onSubmit" @reset="onReset" class="w-600">
        <b-form-row>
          <b-col>
            <b-form-group id="form-account-group" label="Account" label-for="form-account-input" label-cols="2"
              label-cols-lg="3" label-size="sm" size="sm">
              <AccountDropdownVue v-bind:selected_account="apiCLIAccount.account_id" @account-changed="accountChange">
              </AccountDropdownVue>
            </b-form-group>
            <!-- <div align="left">
              <AccountDropdownVue v-bind:selected_account="selected_account" @account-changed="accountChange"></AccountDropdownVue></div>
            <br> -->
          </b-col>
        </b-form-row>
        <b-form-row>
          <b-col>
            <b-form-group id="form-CostCentre-group" label="Cost Centre" label-for="form-CostCentre-input"
              label-cols="2" label-cols-lg="3" label-size="sm" size="xs">
              <div class="dropdown">
                <select style="width: 100%" @change="onChangeCostCentre($event)">
                  <option value="">Select cost center</option>
                  <option v-for="(costcentre, index) in CostCentres" :value="costcentre.costcentre_id" :key="index">
                    {{ costcentre.CostCentreNumber + ' - ' + costcentre.Name }}
                  </option>
                </select>
                <!-- <p v-if="SelectedCostCentre">Selected costcentre - {{ this.SelectedCostCentre }}</p> -->
              </div>
            </b-form-group>
          </b-col>
        </b-form-row>
        <b-form-row>
          <b-col>
            <b-form-group id="form-PhoneNumber-group" label="Outbound" label-for="form-PhoneNumber-input" label-cols="2"
              label-cols-lg="3" label-size="sm" size="sm">
              <b-form-input id="form-PhoneNumber-input" type="text" size="sm" v-model="addCLIForm.PhoneNumber"
                placeholder="Enter outbound">
              </b-form-input>
            </b-form-group>
          </b-col>

        </b-form-row>
        <b-form-row>
          <b-col>
            <b-form-group id="form-Label-group" label="Label" label-for="form-Label-input" label-cols="2"
              label-cols-lg="3" label-size="sm" size="xs">
              <b-form-input id="form-Label-input" type="text" size="sm" v-model="addCLIForm.Label"
                placeholder="Enter label">
              </b-form-input>
            </b-form-group>
          </b-col>


        </b-form-row>
        <b-form-row>
          <b-col>
            <b-form-group id="form-PhoneType-group" label="Phone Type" label-for="form-PhoneType-input" label-cols="2"
              label-cols-lg="3" label-size="sm" size="xs">
              <div class="dropdown">
                <select @change="onChangePhoneType($event)">
                  <option value="">Select phone type</option>
                  <option v-for="(type, index) in phonetypes" :value="type.id" :key="index">
                    {{ type.value }}
                  </option>
                </select>
                <!-- <p v-if="SelectedPhoneType">Selected type - {{ this.SelectedPhoneType }}</p> -->
              </div>
            </b-form-group>
          </b-col>
        </b-form-row>

        <b-form-row>
          <b-col>
            <b-form-group id="form-Notes-group" label="Notes" label-for="form-Note-input" label-cols="2"
              label-cols-lg="3" label-size="sm" size="xs">
              <b-form-textarea id="textarea" v-model="addCLIForm.Notes" placeholder="Enter notes" rows="3"
                max-rows="6"></b-form-textarea>

            </b-form-group>
          </b-col>
        </b-form-row>
        <b-form-row>
          <b-col>
            <p></p>
          </b-col>
        </b-form-row>
        <div class="container">
          <div class="row">
            <div class="col text-center">
              <b-button-group>
                <b-button type="submit" variant="primary">Submit</b-button>
                <b-button type="reset" variant="danger">Reset</b-button>
              </b-button-group>
            </div>
          </div>
        </div>
      </b-form>
    </b-modal>

    <!-- EDIT modal -->
    <b-modal ref="editCLIModal" id="outbound-update-modal" title="Update outbound billing reference" hide-footer size="m">
      <b-form @submit="onSubmitUpdate" @reset="onResetUpdate" class="w-600">
        <b-form-row>
          <b-col>
            <b-form-group id="form-account-group" label="Account" label-for="form-account-input" label-cols="2"
              label-cols-lg="3" label-size="sm" size="sm">
              <AccountDropdownVue v-bind:selected_account="editCLIForm.AccountId" @account-changed="accountChange">
              </AccountDropdownVue>
            </b-form-group>
          </b-col>
        </b-form-row>
        <b-form-row>
          <b-col>
            <b-form-group id="form-CostCentre-group" label="Cost Centre" label-for="form-CostCentre-input"
              label-cols="2" label-cols-lg="3" label-size="sm" size="xs">
              <div class="dropdown">
                <select style="width: 100%" v-model="editCLIForm.CostCentreId" @change="onChangeCostCentre($event)">
                  <option value="">Select cost center</option>
                  <option v-for="(costcentre, index) in CostCentres" :value="costcentre.costcentre_id" :key="index">
                    {{ costcentre.CostCentreNumber + ' - ' + costcentre.Name }}
                  </option>
                </select>
                <!-- <p v-if="SelectedCostCentre">Selected costcentre - {{ this.SelectedCostCentre }}</p> -->
              </div>
            </b-form-group>
          </b-col>
        </b-form-row>
        <b-form-row>
          <b-col>
            <b-form-group id="form-PhoneNumber-group" label="Outbound" label-for="form-PhoneNumber-input" label-cols="2"
              label-cols-lg="3" label-size="sm" size="sm">
              <b-form-input id="form-PhoneNumber-input" type="text" size="sm" v-model="editCLIForm.PhoneNumber" readonly
                placeholder="Enter outbound">
              </b-form-input>
            </b-form-group>
          </b-col>

        </b-form-row>
        <b-form-row>
          <b-col>
            <b-form-group id="form-Label-group" label="Label" label-for="form-Label-input" label-cols="2"
              label-cols-lg="3" label-size="sm" size="xs">
              <b-form-input id="form-Label-input" type="text" size="sm" v-model="editCLIForm.Label"
                placeholder="Enter label">
              </b-form-input>
            </b-form-group>
          </b-col>


        </b-form-row>
        <b-form-row>
          <b-col>
            <b-form-group id="form-PhoneType-group" label="Phone Type" label-for="form-PhoneType-input" label-cols="2"
              label-cols-lg="3" label-size="sm" size="xs">
              <div class="dropdown">
                <select v-model="editCLIForm.PhoneType" @change="onChangePhoneType($event)">
                  <option value="">Select phone type</option>
                  <option v-for="(type, index) in phonetypes" :value="type.id" :key="index">
                    {{ type.value }}
                  </option>
                </select>
                <!-- <p v-if="SelectedPhoneType">Selected type - {{ this.SelectedPhoneType }}</p> -->
              </div>
            </b-form-group>
          </b-col>
        </b-form-row>
        <b-form-row>
          <b-col>
            <b-form-group id="form-Notes-group" label="Notes" label-for="form-Note-input" label-cols="2"
              label-cols-lg="3" label-size="sm" size="xs">
              <b-form-textarea id="textarea" v-model="editCLIForm.Notes" rows="3" max-rows="6"></b-form-textarea>

            </b-form-group>
          </b-col>
        </b-form-row>
        <b-form-row>
          <b-col>
            <p></p>
          </b-col>
        </b-form-row>
        <div class="container">
          <div class="row" v-if="isAdmin">
            <div class="col text-center">
              <b-button-group>
                <b-button type="submit" variant="primary">Submit</b-button>
                <b-button type="reset" variant="danger">Reset</b-button>
              </b-button-group>
            </div>
          </div>
        </div>
      </b-form>
    </b-modal>
  </div>
</template>

<script>
import Alert from '../components/Alert.vue';
import Filter from '../components/Filter.vue';
import AccountDropdownVue from "../components/AccountDropdown.vue";


import {isAdmin, editButtonText} from '@/auth/AuthService'
export default {
  Name: "Api",
  data() {
    return {
      isAdmin: isAdmin(),
      editButtonText: editButtonText(),
      phonetypes: [{ "id":"L", "value":'Landline      '}, {"id":"M","value":'Mobile'}],
      SelectedPhoneType: '',
      SelectedCostCentre: null,
      loadOutboundForm: {
        FileName: ''
        },

      addCLIForm: {
        Name: '' ,
        BillingName: '' ,
        PhoneNumber: null ,
        Address2: null ,
        PostCode: null ,
        City: null ,
        Country: null ,
        ContactName: null,
        ContactNumber: null,
        FaxNumber: null ,
        CostCentreId: null,
        PhoneType: null,
        Notes: null
      },
      editCLIForm: {
        AccountId: null,
        OutboundId: null,
        Name: null ,
        BillingName: null ,
        PhoneNumber: null ,
        Address2: null ,
        PostCode: null ,
        City: null ,
        Country: null ,
        ContactName: null,
        ContactNumber: null,
        FaxNumber: null ,
        CostCentreId: null,
        PhoneType: null,
        Notes: null,
        NewNotes: null
      },
      apiCostCentreDetails :null,
      apiCLIs: null,
      apiCLIAccount:{},
      executed: false,
      message: 'initial alert',
      currentSort:'PhoneNumber',
      currentSortDir:'asc',
      pageSize:20,
      showPaging:true,
      currentPage:1,
      filter:'',
      ShowAccount:true,
      selected_account: null,
      //ShowBundle:false,
      selected_mobilebundle: null,
      CostCentres: null
    };
  },

  computed:{
  filteredCLIs() {
    //  console.log('filtered outbound');
      return this.apiCLIs.filter(c => {
        if(this.filter == '') return true;
        return c.PhoneNumber.toLowerCase().indexOf(this.filter.toLowerCase()) >= 0 ||
               c.account.Name.toLowerCase().indexOf(this.filter.toLowerCase()) >= 0
      })
    },
  SortedapiCLIs:function() {
    //  console.log('sort outbound');
    if (this.apiCLIs != null){
      return this.filteredCLIs.slice().sort((a,b) => {
        let modifier = 1;
        if(this.currentSortDir === 'desc') modifier = -1;
        if(this.currentSort === 'account.Name'){
          var prop = this.currentSort.split('.');
          var len = prop.length;
            var i = 0;
            while( i < len ) { a = a[prop[i]]; b = b[prop[i]]; i++; }
            if (a < b) {
                return -1 * modifier;
            } else if (a > b) {
                return 1 * modifier;
            } else {
                return 0;
            }
        }
        else{
          if(a[this.currentSort].toLowerCase() < b[this.currentSort].toLowerCase()) return -1 * modifier;
          if(a[this.currentSort].toLowerCase() > b[this.currentSort].toLowerCase()) return 1 * modifier;
          return 0;
        }
      }).filter((row, index) => {
        let start = (this.currentPage-1)*this.pageSize;
        let end = this.currentPage*this.pageSize;
        if(index >= start && index < end) return true;
      });
    }
        else{ return null}
  },
  },
  methods: {
    download: function() {
        console.log("in download")
        var data = "AccountNumber,CostCentre,Outbound,Label,PhoneType,Notes,Action"
        var fileURL = window.URL.createObjectURL(new Blob([data]));
        var fileLink = document.createElement('a');
        fileLink.href = fileURL;
        fileLink.setAttribute('download', "UploadOutboundBillingReferences.txt");
        document.body.appendChild(fileLink);

        fileLink.click();

      },
    onChangePhoneType(event) {
      this.SelectedPhoneType= event.target.value

      //  console.log(event.target.value)


      // this.$emit('account-changed',this.SelectedAccount)
    },
    onChangeCostCentre(event) {
      this.SelectedCostCentre= event.target.value

      //  console.log(event.target.value)


      // this.$emit('account-changed',this.SelectedAccount)
    },
    accountChange: function (e) {
        this.selectedAccount = e;
    },
    mobilebundleChange: function (e) {
        this.selectedMobileBundle = e;
    },
    nextPage:function() {
      if((this.currentPage*this.pageSize) < this.filteredCLIs.length) this.currentPage++;
    },
    prevPage:function() {
      if(this.currentPage > 1) this.currentPage--;
    },
    sort:function(s) {
    //if s == current sort, reverse
    if(s === this.currentSort) {
      this.currentSortDir = this.currentSortDir==='asc'?'desc':'asc';
    }
    this.currentSort = s;
    },
    // *******************************************
    // get costcentre details
    // *******************************************
    async getCostCentreDetails(costcentre) {

      console.log(costcentre)
      console.log('getting the account data')
      try {
        document.body.style.cursor = "wait";
        var url;
        url =  process.env.VUE_APP_API_BASE_URL + '/api/v1/costcentres/' + costcentre
        const { data } = await this.$http.get(url);
        document.body.style.cursor = 'auto';
        this.apiCostCentreDetails = data;
        console.log('got the account data from api')
        console.log(data)
        this.executed = true;
      } catch (e) {
        this.apiAccounts = `Error: the server responded with '${e.response}'`;
      }
    },
    // *******************************************
    // List costcentres
    // *******************************************
    async callCostCentreApi(lablex) {

       console.log(lablex)
      //  console.log('getting the costcentre list')
      //  console.log(this.$route.query.account_id)
      //  console.log('getting the costcentre list')
      var url;
      if (typeof(this.$route.query.account_id) != "undefined"){
          //  console.log('selected account in CostCentre is:');
          //  console.log(this.$route.query.account_id)
          url = process.env.VUE_APP_API_BASE_URL + '/api/v1/costcentres?q={"filters":[{"name":"AccountId","op":"eq","val":"' + this.$route.query.account_id + '"}]}';
          //  console.log(url)
      }
      else{
          url = `${process.env.VUE_APP_API_BASE_URL}/api/v1/costcentres`
      }
      try {
        const { data } = await this.$http.get(url);

        this.CostCentres = data;
        console.log('cost center data')
        console.log(data)
        if (this.apiCostCentres.length < this.pageSize){
          this.showPaging = false
        }
        //  console.log(this.CostCentres)

        this.executed = true;
      } catch (e) {
        this.apiCostCentres = `Error: the server responded with '${e.response}'`;
      }
    },

    // *******************************************
    // List outbounds
    // *******************************************
    async callApi(lablex) {

       console.log(lablex)
      //  console.log('getting the outbound list')
      //  console.log(this.$route.query.account_id)
      //  console.log('getting the outbound list')
      var url;
      if(typeof(this.$route.query.costcentre_id) != "undefined"){
        url = process.env.VUE_APP_API_BASE_URL + '/api/v1/outbounds?q={"filters":[{"name":"CostCentreId","op":"eq","val":"' + this.$route.query.costcentre_id + '"}]}';
        this.getCostCentreDetails(this.$route.query.costcentre_id)
        this.ShowAccount = false
        // this.selected_account = this.$route.query.account_id
      }
      else if (typeof(this.$route.query.account_id) != "undefined"){
          url = process.env.VUE_APP_API_BASE_URL + "/api/v1/accounts/" + this.$route.query.account_id + "/outbounds"
          this.ShowAccount = false
          this.selected_account = this.$route.query.account_id
          this.getAccountApi(this.selected_account);
          console.log("IN CLI ACCOUNT)")
          console.log(this.selected_account)
      }
      else if(typeof(this.$route.query.costcentre_id) != "undefined"){
        url = process.env.VUE_APP_API_BASE_URL + '/api/v1/outbounds?q={"filters":[{"name":"CostCentreId","op":"eq","val":"' + this.$route.query.costcentre_id + '"}]}';
        this.ShowAccount = false
        this.selected_account = this.$route.query.account_id
      }
      else{
          url = `${process.env.VUE_APP_API_BASE_URL}/api/v1/outbounds`
      }
      try {
        document.body.style.cursor = 'wait';
        const { data } = await this.$http.get(url);
        document.body.style.cursor = 'auto';
        this.apiCLIs = data;
        if (this.apiCLIs.length < this.pageSize){
          this.showPaging = false
        }
        //  console.log(this.apiCLIs )
        //  console.log(this.apiCLIs.length)
        this.executed = true;
      } catch (e) {
        this.apiCLIs = `Error: the server responded with '${e.response}'`;
      }
    },
    // *******************************************
    // get account details
    // *******************************************
    async getAccountApi(accountid) {

      console.log(accountid)
      console.log('getting the account data')
      try {
        document.body.style.cursor = "wait";
        var url;
        url =  process.env.VUE_APP_API_BASE_URL + '/api/v1/accounts/' + accountid
        const { data } = await this.$http.get(url);
        document.body.style.cursor = 'auto';
        this.apiCLIAccount = data;
        console.log('got the account data from api')
        console.log(data)
        this.executed = true;
      } catch (e) {
        this.apiAccounts = `Error: the server responded with '${e.response}'`;
      }
    },
    // *******************************************
    // Load on submit
    // *******************************************
    onLoadSubmit(evt) {
      console.log('on load submit');
      evt.preventDefault();
      this.$refs.loadOutboundModal.hide();
      const payload = {
        FileName: this.loadOutboundForm.FileName
      };
      this.loadoutbounds(payload);
      //this.initForm();
    },
    onLoadReset(evt) {
    evt.preventDefault();
    this.$refs.loadOutboundModal.hide();
    this.initForm();
    },
    // Add cdrload method to post to API
    async loadoutbounds(payload) {

      let formData = new FormData();
      console.log( 'in load outbounds');
      console.log( payload.file);
      formData.append('file', payload.FileName);
      var url;
      
      url = `${process.env.VUE_APP_API_BASE_URL}/api/v1/outbounds/load`
      try {
        document.body.style.cursor = 'wait';
        const { data } =  this.$http.post(url, formData).then(() => {
          this.message = 'cost centre loaded!';
          this.showMessage = true;
          document.body.style.cursor = 'auto';
          this.callApi();
        }).catch((err) => {
          if(err.response.status == 409) {
            alert("Duplicated cdrload. status code:  " + err.response.status)
            }
          if(err.response.status != 409) {
            alert("Problem with add cdrload. status code:  " + err.response.status)
            }
          console.error(err);
          document.body.style.cursor = 'auto';
          this.callApi();

        });

        // this.apicdrloads = data;
        // this.executed = true;
        console.log(data)
        //this.callApi()
      } catch (e) {
        this.apicdrloads = `Error: the server responded with '${e.response}'`;
      }
    },
    // *******************************************
    // Delete outbound
    // *******************************************
    async removeCLI(outboundid) {

      //  console.log("in removeCLI before")
       console.log(outboundid)
      try {
        const { data } =  this.$http.delete(`${process.env.VUE_APP_API_BASE_URL}/api/v1/outbounds/${outboundid}`).then(() => {
          this.message = 'outbound removed!';
          this.showMessage = true;
          console.log(data)
          //  console.log("in removeCLI after")
          this.callApi("coming from delete");

        }).catch((err) => {
          if (err.response.status == 400) {
            console.log(err.response)
            console.log(err.response.data.message)
            alert("Problem deleting outbound. " + err.response.data.message)
          }
        });

        //  console.log('delete data is:')
        //  console.log(data)
        // this.callApi()
      } catch (e) {
        this.apiCLIs = `Error: the server responded with '${e.response}'`;
      }
    },
    onDeleteCLI(outbound) {
      //  console.log(outbound.OutboundId)
      if(confirm("Are you sure you want to delete this outbound?")){
        this.removeCLI(outbound.outbound_id);
      }
    },

    // Add outbound method to post to API
    async addCLI(payload) {

    //   const id_token = claims.__raw;
      //  console.log('in add outbound');
      //  console.log('payload is');
       console.log(payload);
      try {
        const { data } =  this.$http.post(`${process.env.VUE_APP_API_BASE_URL}/api/v1/outbounds`, payload).then(() => {
          this.message = 'outbound added!';
          this.showMessage = true;
          this.callApi();

        }).catch((err) => {
          if(err.response.status == 409) {
            alert("Duplicated outbound. status code:  " + err.response.status)
            }
          if(err.response.status != 409) {
            alert("Problem with add outbound. status code:  " + err.response.status)
            }
          //  console.error(err);
          this.callApi();

        });
         console.log(data)
        this.callApi()
      } catch (e) {
        this.apiCLIs = `Error: the server responded with '${e.response}'`;
      }
    },
    initForm() {
      //  console.log('init form');
        this.addCLIForm.PhoneNumber =  null;
        this.addCLIForm.SelectedPhoneType=  null;
        this.addCLIForm.Label = null;
        this.addCLIForm.SelectedCostCentre=  null;
        this.addCLIForm.PhoneType = null;
    },
    // *******************************************
    // Add outbound on submit
    // *******************************************
    onSubmit(evt) {
      //  console.log('on submitXXXXXXXXXXXXX');
      evt.preventDefault();
      this.$refs.addCLIModal.hide();
      var accountid;
      if (this.selected_account != "" && this.selected_account != null){
        accountid = this.selected_account
      }
      else{
        accountid = this.selectedAccount
      }
      const payload = {
        PhoneNumber: this.addCLIForm.PhoneNumber,
        Label: this.addCLIForm.Label,
        PhoneType: this.SelectedPhoneType,
        CostCentreId: this.SelectedCostCentre,
        AccountId: accountid,
        Notes:this.addCLIForm.Notes
      };
      //  console.log('in add outbound ')
      //  console.log(payload)
      this.addCLI(payload);
      this.initForm();
    },
    onReset(evt) {
      evt.preventDefault();
      this.$refs.addCLIModal.hide();
      this.initForm();
    },
  //
    editCLI(outbound) {
      //  console.log('editCLI');
      //  console.log(outbound);
      //  console.log(outbound.outbound_id);
      this.editCLIForm = outbound;
      this.selectedAccount = outbound.AccountId
      this.SelectedCostCentre = outbound.CostCentreId
      this.SelectedPhoneType = outbound.PhoneType
      //this.selected_mobilebundle = outbound.MobileBundleId
      // show bundle for mobile outbounds
      // if (outbound.PhoneType == "M"){
      //  this.ShowBundle = true;
      //}
      //else{
      //  this.ShowBundle = false;
      //}
      this.callApi()
    },

    // *******************************************
    // Update outbound on submit
    // *******************************************
    onSubmitUpdate(evt) {
       console.log('in onSubmitUpdatexxxxxxxxxxxxx');
       console.log('selected type is');
       console.log(this.SelectedPhoneType);
      //  console.log('outbound_id');
      evt.preventDefault();
      this.$refs.editCLIModal.hide();
      const payload = {
        PhoneNumber: this.editCLIForm.PhoneNumber,
        Label: this.editCLIForm.Label,
        PhoneType: this.SelectedPhoneType,
        AccountId: this.selectedAccount,
        CostCentreId: this.SelectedCostCentre,
        //MobileBundleId: this.selectedMobileBundle,
        Notes: this.editCLIForm.Notes
      };
      this.updateCLI(payload, this.editCLIForm.outbound_id);
      this.callApi()
    },

    async updateCLI(payload, outboundid) {

      //  console.log('in update outbound');
       console.log(payload);
      try {
        const { data } =  this.$http.put(`${process.env.VUE_APP_API_BASE_URL}/api/v1/outbounds/${outboundid}`, payload).then(() => {
          this.message = 'outbound updated!';
          this.showMessage = true;
          this.callApi();

        }).catch((err) => {
          if(err.response.status == 409) {
            alert("Duplicated outbound. status code:  " + err.response.status)
            }
          if(err.response.status != 409) {
            alert("Problem with add outbound. status code:  " + err.response.status)
            }

          //  console.error(err);
          this.callApi();

        });

        // this.apiCLIs = data;
        // this.executed = true;
        //  console.log('delete data is:')
         console.log(data)
        this.callApi()
      } catch (e) {
        this.apiCLIs = `Error: the server responded with '${e.response}'`;
      }
    },
    onResetUpdate(evt) {
      evt.preventDefault();
      this.$refs.editCLIModal.hide();
      this.initForm();
      this.getCLIs(); // why?
    },
  },

  created() {
    //  console.log('created')
    this.callCostCentreApi();
    this.callApi();


  },
  components: {
    alert: Alert,
    listfilter:Filter,
    AccountDropdownVue: AccountDropdownVue,
  },
};
</script>
