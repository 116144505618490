<template>

    <div>
      <p ><u><strong>Suspended Usage Report</strong></u> </p>
      <br>
      <div class="result-block-container">

        <div :class="['result-block', executed ? 'show' : '']">
        <b-form @submit="onSubmit" >
        <b-form-group
            id="fieldset-horizontal"
            label-cols-sm="2"
            label-cols-lg="2"
            content-cols-sm
            content-cols-lg="4"
            label="Report"
            label-for="input-horizontal"
            >
            <div class="dropdown">
                <select @change="onChangeReportType($event)">
                  <option value="">Select report</option>
                  <option
                    v-for="(type, index) in reporttypes"
                    :value="type.id"
                    :key="index"
                  >
                    {{ type.value }}
                  </option>
                </select>
                <!-- <p v-if="SelectedPhoneType">Selected type - {{ this.SelectedPhoneType }}</p> -->
              </div>
            </b-form-group>
            <table>
              <tr>
                <td><b-button type="submit" variant="primary">Find</b-button></td>
                <td></td>
                <td><button type="button" class="btn btn-success btn-sm" v-on:click="downloadAll">Dowload All</button></td>
            </tr>
            </table>
  </b-form>

         <br><br>
         <table v-show="showresult" >
            <tr v-if="apiBaseRates.length > 0">
                <td><button type="button" class="btn btn-success btn-sm" v-on:click="download">Dowload Results</button></td>
            </tr>
        </table>
        <br><br>
          <table class="table table-striped w-auto" v-if="SelectedReportType == 'UC'" v-show="showresult" id="apirecords">
            <thead >
            <tr>
              <th class="th-lg" scope="col" @click="sort('BillingReference')" >Billing Reference</th>
              <!-- <th class="th-lg" scope="col" @click="sort('Direction')" >Direction</th> -->
              <th class="th-sm" scope="col" @click="sort('CallCount')" >Count</th>
              <th class="th-sm" scope="col" @click="sort('DurationMin')">Duration (m) </th>
              <th class="th-sm" scope="col" @click="sort('DataMB')">Data (MB) </th>
              <th class="th-sm" scope="col" @click="sort('CarrierCost')" >Cost (£) </th>
              <th class="th-sm" scope="col" @click="sort('Carrier')">Carrier</th>
              <th class="th-sm" scope="col" >Reason</th>
              
              
            </tr>
          </thead>
          <tbody>
            <tr v-for="(chargeband, index) in SortedapiBaseRates" :key="index">
              <td>{{ chargeband.BillingReference }}</td>
              <!-- <td>{{ chargeband.Direction }}</td> -->
              <td>{{ chargeband.CallCount }}</td>
              <td>{{ chargeband.DurationMin }}</td>
              <td>{{ chargeband.DataMB }}</td>
              <td>{{ chargeband.CarrierCost }}</td>
              <td>{{ chargeband.Carrier }}</td>
              <td>{{ chargeband.Reason }}</td>
            </tr>
          </tbody>
        </table>
          <table class="table table-striped w-auto" v-if="SelectedReportType == 'UD'"  v-show="showresult" id="apirecords">
            <thead >
            <tr>
              
              <!-- <th class="th-lg" scope="col" @click="sort('Direction')" >Direction</th> -->
              <th class="th-lg" scope="col" @click="sort('Destination')" >Destination</th>
              <th class="th-sm" scope="col" @click="sort('CallCount')" >Count</th>
              <th class="th-sm" scope="col" @click="sort('DurationMin')">Duration (m) </th>
              <th class="th-sm" scope="col" @click="sort('DataMB')">Data (MB) </th>
              <th class="th-sm" scope="col" @click="sort('CarrierCost')" >Cost (£) </th>
              <th class="th-sm" scope="col" @click="sort('Carrier')">Carrier</th>
              <th class="th-sm" scope="col" >Reason</th>
              
              
            </tr>
          </thead>
          <tbody>
            <tr v-for="(chargeband, index) in SortedapiBaseRates" :key="index">
              <!-- <td>{{ chargeband.Direction }}</td> -->
              <td>{{ chargeband.Destination }}</td>             
              <td>{{ chargeband.CallCount }}</td>
              <td>{{ chargeband.DurationMin }}</td>
              <td>{{ chargeband.DataMB }}</td>
              <td>{{ chargeband.CarrierCost }}</td>
              <td>{{ chargeband.Carrier }}</td>
              <td>{{ chargeband.Reason }}</td>
            </tr>
          </tbody>
          </table>
          <input v-if="SelectedReportType == 'IU'"  v-show="showresult" type="search" v-model="filter">
          <table class="table table-striped w-auto" v-if="SelectedReportType == 'IU'"  v-show="showresult" id="apirecords">
            <thead>
              <tr>
                <th scope="col" @click="sort('GroupNumber')">Group Number</th>
                <th scope="col" @click="sort('GroupName')">Group Name</th>
                <th scope="col" @click="sort('AccountNumber')">Account Number</th>
                <th scope="col" @click="sort('AccountName')">Account Name</th>
                <th scope="col" @click="sort('CostCentreName')">Cost Centre Name</th>
                <th scope="col" @click="sort('BillingReference')">Billing Reference</th>
                <th scope="col" @click="sort('Label')">Label</th>
                <!-- <th scope="col" @click="sort('Direction')">Direction</th> -->
                <th scope="col" @click="sort('Destination')">Destination</th>
                <th scope="col" @click="sort('Date')">Date</th>
                <th scope="col" @click="sort('Time')">Time</th>
                <th scope="col" @click="sort('Duration')">Duration (m)</th>
                <th scope="col" @click="sort('Data_mb')">Data (MB)</th>
                <th scope="col" @click="sort('Cost')">Cost (£)</th>
                <th scope="col" @click="sort('Carrier')">Carrier</th>  
                <th class="th-sm" scope="col" >Reason</th>
              </tr>
            </thead>
            <tbody>
              <tr v-for="(chargeband, index) in SortedapiBaseRates" :key="index">
                <td>{{ chargeband.GroupNumber }}</td>
                <td>{{ chargeband.GroupName }}</td>
                <td>{{ chargeband.AccountNumber }}</td>
                <td>{{ chargeband.AccountName }}</td>
                <td>{{ chargeband.CostCentreName }}</td>
                <td>{{ chargeband.BillingReference }}</td>
                <td>{{ chargeband.Label }}</td>
                <!-- <td>{{ chargeband.Direction }}</td> -->
                <td>{{ chargeband.Destination }}</td>
                <td>{{ chargeband.Date }}</td>
                <td>{{ chargeband.Time }}</td>
                <td>{{ chargeband.DurationMin }}</td>
                <td>{{ chargeband.Data_mb }}</td>
                <td>{{ chargeband.Cost }}</td>
                <td>{{ chargeband.Carrier }}</td>
                <td>{{ chargeband.Reason }}</td>
                
              </tr>
            </tbody>
          </table>
          <div v-show="showPaging"><p align="center">
            <b-pagination
              v-model="currentPage"
              align="center"
              :total-rows="filteredBaseRates.length"
              :per-page="pageSize"
              aria-controls="apirecords"
              ></b-pagination>
            </p></div>
            
        </div>
      </div>

    </div>
  </template>

  <script>
  import Alert from '../components/Alert.vue';
  import Filter from '../components/Filter.vue';
  import * as XLSX from 'xlsx'

import {isAdmin, editButtonText} from '@/auth/AuthService'
export default {
  Name: "Api",
  data() {
    return {
      isAdmin: isAdmin(),
      editButtonText: editButtonText(),
        reporttypes: [{ "id":"UC", "value":'Unknown Billing Reference Summary     '}, {"id":"UD","value":'Unknown Destination Summary'}, {"id":"IU","value":'Itemised Usage'}],
        executed: false,
        message: 'initial alert',
        currentSort:'Carrier',
        currentSortDir:'asc',
        pageSize:20,
        currentPage:1,
        filter:'',
        showresult: false,
        showdownload:false,
        showPaging:false,
        Account: null,
        apiBaseRates: [],
        SelectedReportType: '',
        tableid : ''
      };
    },

    computed:{
    filteredBaseRates() {
      console.log('filtered suspendedcall');
        // return this.apiBaseRates;
        return this.apiBaseRates.filter(c => {
            // console.log('in filter')
          if(this.filter == '') return true;
          if (c.AccountName == null) c.AccountName = '';
          if (c.AccountNumber == null) c.AccountNumber = '';
          if (c.GroupName == null) c.GroupName = '';
          if (c.AccountNumber == null) c.AccountNumber = '';
          if (c.Label == null) c.Label = '';
          if (c.CostCentreName == null) c.CostCentreName = '';
          return  c.Carrier.toLowerCase().indexOf(this.filter.toLowerCase()) >= 0 ||
          (c.BillingReference && c.BillingReference.toLowerCase().indexOf(this.filter.toLowerCase())) >=0 ||
          (c.Destination && c.Destination.toLowerCase().indexOf(this.filter.toLowerCase())) >=0  ||
          c.AccountName.toLowerCase().indexOf(this.filter.toLowerCase()) >=0 ||
          c.AccountNumber.toLowerCase().indexOf(this.filter.toLowerCase()) >=0 ||
          c.GroupNumber && c.GroupNumber.toLowerCase().indexOf(this.filter.toLowerCase()) >=0 ||
          c.GroupName && c.GroupName.toLowerCase().indexOf(this.filter.toLowerCase()) >=0 ||
          c.Label && c.Label.toLowerCase().indexOf(this.filter.toLowerCase())  >=0 ||
          c.CostCentreName && c.CostCentreName.toLowerCase().indexOf(this.filter.toLowerCase())  >=0
          
        })
      },
    SortedapiBaseRates:function() {
        console.log('sort suspendedcall');
        console.log(this.currentSort);
        if (this.apiBaseRates != null){
            return this.filteredBaseRates.slice().sort((a,b) => {
              let modifier = 1;
              if(this.currentSortDir === 'desc') modifier = -1;
              if(a[this.currentSort].toLowerCase() < b[this.currentSort].toLowerCase()) return -1 * modifier;
              if(a[this.currentSort].toLowerCase() > b[this.currentSort].toLowerCase()) return 1 * modifier;
              return 0;
            }).filter((row, index) => {
              let start = (this.currentPage-1)*this.pageSize;
              let end = this.currentPage*this.pageSize;
              if(index >= start && index < end) return true;
            });
          }

        else{ return null}
      }
    },
    methods: {
    onChangeReportType(event) {
            this.showresult = false;
            this.showPaging = false;
            this.SelectedReportType= event.target.value;
            },


    onSubmit(evt) {
        console.log('on submit');
        this.apiBaseRates = [];
        this.showresult = false;
        if (!this.SelectedReportType){ // is null or zero
            alert("Please select a report");
            evt.preventDefault();
                    return false;
            }

        if (this.SelectedReportType== 'UC'){
          this.currentSort = 'BillingReference';
        }
        
        if (this.SelectedReportType== 'UD'){
          this.currentSort = 'Destination';
        }

        this.callApi(this.SelectedReportType);
        // this.showresult = true;
        evt.preventDefault();
        },

    download : function() {
        // const data = XLSX.utils.json_to_sheet(this.filteredBaseRates)
        // const wb = XLSX.utils.book_new()
        // // XLSX.utils.book_append_sheet(wb, data, 'data')
        // var moment = require('moment');
        // var datestr = moment(new Date()).format('DD-MM-YYYY');
        var data;
        var datestr;
        var moment;
        var filename;
        var Heading;
        var objHeader;
        console.log('GGGGGGGGGGGGGGGGGGGGGGGGGGGGGGGGGGGGGGGGGGGGGGG')
        console.log(this.SelectedReportType)
        if (this.SelectedReportType== 'UC'){
              // customised headers
              Heading = [
                                  ['Billing Reference','Count','Duration (m)', 'Data (MB)','Cost (£)','Carrier', 'Reason']
                              ];

              // original object headers
              objHeader =  ['BillingReference','CallCount','DurationMin','DataMB','Cost','Carrier', 'Reason']

              // build based on original object fields but skip the header row
              data = XLSX.utils.json_to_sheet(this.filteredBaseRates, { origin: 'A2', header: objHeader, skipHeader: true })
              // add the customised header
              XLSX.utils.sheet_add_aoa(data, Heading, { origin: 'A1' });

              const wb = XLSX.utils.book_new()
              XLSX.utils.book_append_sheet(wb, data, 'data')
              moment = require('moment');
              datestr = moment(new Date()).format('DD-MM-YYYY');
              // add the customised header
              XLSX.utils.sheet_add_aoa(data, Heading, { origin: 'A1' });

              filename = 'Suspended Usage (Unknown Billing Reference) Report -' + datestr + '.xlsx';
              XLSX.writeFile(wb,filename)
            
        }
        if (this.SelectedReportType== 'UD'){
          // customised headers
          Heading = [
                                  ['Destination','Count','Duration (m)', 'Data (MB)','Cost (£)','Carrier', 'Reason']
                              ];

              // original object headers
              objHeader =  ['Destination','CallCount','DurationMin','DataMB','Cost','Carrier', 'Reason']

              // build based on original object fields but skip the header row
              data = XLSX.utils.json_to_sheet(this.filteredBaseRates, { origin: 'A2', header: objHeader, skipHeader: true })
              // add the customised header
              XLSX.utils.sheet_add_aoa(data, Heading, { origin: 'A1' });

              const wb = XLSX.utils.book_new()
              XLSX.utils.book_append_sheet(wb, data, 'data')
              moment = require('moment');
              datestr = moment(new Date()).format('DD-MM-YYYY');
              // add the customised header
              XLSX.utils.sheet_add_aoa(data, Heading, { origin: 'A1' });

              filename = 'Suspended Usage (Unknown Destination) Report -' + datestr + '.xlsx';
              XLSX.writeFile(wb,filename)
        }
        if (this.SelectedReportType== 'IU'){
              // customised headers
               Heading = [
                                  ['Group Number','Group Name','Account Number','Account Name','Cost Centre Name','Billing Reference','Label','Destination','Date','Time','Duration (m)','Data (MB)','Cost (£)','Carrier', 'Reason']
                              ];

              // original object headers
               objHeader =  ['GroupNumber','GroupName','AccountNumber','AccountName','CostCentreName','BillingReference','Label','Destination','Date','Time','DurationMin','Data_mb','Cost','Carrier', 'Reason']

              // build based on original object fields but skip the header row
              data = XLSX.utils.json_to_sheet(this.filteredBaseRates, { origin: 'A2', header: objHeader, skipHeader: true })
              // add the customised header
              XLSX.utils.sheet_add_aoa(data, Heading, { origin: 'A1' });

              const wb = XLSX.utils.book_new()
              XLSX.utils.book_append_sheet(wb, data, 'data')
              moment = require('moment');
              datestr = moment(new Date()).format('DD-MM-YYYY');
              // add the customised header
              XLSX.utils.sheet_add_aoa(data, Heading, { origin: 'A1' });

              filename =  'Suspended Usage (Itemised Usage) Report - ' + datestr + '.xlsx';
              XLSX.writeFile(wb,filename)
           
        }
      },

      // api get download
      async downloadAll() {

        console.log('***********************download*****************************')
        // var url;
        try {

          document.body.style.cursor = "wait";
          const { data } = await this.$http.get(`${process.env.VUE_APP_API_BASE_URL}/api/v1/reports/suspendeddownloadall`,{ // Note the change here
          responseType: 'blob',
        });
          var moment = require('moment');
          var datestr = moment(new Date()).format('DD-MM-YYYY');
          document.body.style.cursor = 'auto';
          console.log(' in download all after get')
          this.executed = true;
          var fileURL = window.URL.createObjectURL(new Blob([data]));
          var fileLink = document.createElement('a');
          fileLink.href = fileURL;
          fileLink.setAttribute('download', "SuspendedUsageSummary(All)-" + datestr + ".xlsx");
          document.body.appendChild(fileLink);

          fileLink.click();

        } catch (e) {
          this.apiInvoices = `Error: the server responded with '${e.response}'`;
        }
        },
          

    nextPage:function() {
        if((this.currentPage*this.pageSize) < this.filteredBaseRates.length) this.currentPage++;
      },

    prevPage:function() {
        if(this.currentPage > 1) this.currentPage--;
      },

    sort:function(s) {
      //if s == current sort, reverse
      if(s === this.currentSort) {
        this.currentSortDir = this.currentSortDir==='asc'?'desc':'asc';
      }
      this.currentSort = s;
      },

      // *******************************************
      // List details
      // *******************************************
    async callApi(report) {
        console.log('getting the data')
        // build the filters for the url
        var url;
        if (report== 'UC'){
            url = process.env.VUE_APP_API_BASE_URL + '/api/v1/reports/suspendedunknowncli'
            this.tableid = 'apirecordsUC'
        }
        if (report== 'UD'){
            url = process.env.VUE_APP_API_BASE_URL + '/api/v1/reports/suspendedunknowndest'
            this.tableid = 'apirecordsUD'
        }
        if (report== 'IU'){
            url = process.env.VUE_APP_API_BASE_URL + '/api/v1/reports/suspendeditemisedusage'
            this.tableid = 'apirecordsIU'
        }

        document.body.style.cursor = 'wait';

        try {

          console.log(url)
          const { data } = await this.$http.get(url);
          console.log(data);
          this.apiBaseRates = data;
          console.log('lllllllllllllllllllllllllllllllllllllllllllllllllll')
          console.log(this.apiBaseRates.length)
          console.log(this.pageSize)
          if (this.apiBaseRates.length > this.pageSize){
            this.showPaging = true
          }
          else{
            this.showPaging = false
          }
          // this.showPaging = true;
          // if (this.apiBaseRates.length < this.pageSize){
          //   this.showPaging = false
          // }
          this.showresult = true
          this.executed = true;
        } catch (e) {
          this.apiBaseRates = `Error: the server responded with '${e.response}'`;
        }
        document.body.style.cursor = 'auto';
      },

    },

    created() {
      console.log('created')
      this.executed = true;
    //   this.callApi();

    },
    components: {
      alert: Alert,
      listfilter:Filter
    },
  };
  </script>
